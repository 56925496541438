.wrapper .instructions-wrapper {
  background: var(--lnd-blue-light);
}

.instructions {
  grid-column: 1 / -1;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.instructions-headline {
  text-align: center;
}

.where-to {
  width: 100%;
}

.where-to li {
  margin-bottom: 12px;
}

.where-to li::marker {
  color: #4D83FA;
  margin-right: 12px;
}

@media screen and (max-width: 960px) {
  .instructions {
    grid-column: 1 / -1;
  }
}