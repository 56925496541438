.btn {
  box-sizing: border-box;
  align-items: center;
  appearance: none;
  border: 0;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  outline: none;
  padding: 0;
  position: relative;
  text-align: center;
  user-select: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 20px;
  color: #0d4cd3;
  font-family: Lato, 'sans-serif';
}

.btn.back {
  padding-left: 15px;
}

.btn.back::before {
  content: '';
  position: absolute;
  display: block;
  box-sizing: border-box;
  left: 0;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
  border-top: 2px solid #0d4cd3;
  border-left: 2px solid #0d4cd3;
  transform: rotate(-45deg);
}
