.statistics-wrapper {
  background: #E4ECFD;
}

.statistics-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column: 2 / -2;
  gap: 24px;
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
}

.statistic-block {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 32px 24px;
  background: #FFFFFF;
  border-radius: 24px;
}

.statistic-info {
  display: block;
  margin-top: 24px;
  text-align: center;
}

.statistic-info span:first-child {
  display: block;
  margin-bottom: 12px;
}

.statistic-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: 700;
  color: var(--lnd-blue-dark);
}

.statistic-text {
  color: var(--lnd-blue-dark);
}

@media screen and (max-width: 960px) {
  .statistics-container {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 767px) {
  .statistics-container {
    grid-template-columns: 1fr;
  }
}