.menuItem {
  padding: 12px;
  display: flex;
  cursor: pointer;
}

.menuItem:hover {
  background-color: var(--lnd-color-gray-light);
}

.menuItem.menuItemActive {
  background-color: #0066b3;
  color: var(--lnd-color-gray-light);
}

.menuInfo {
  padding: 0 0 0 12px;
  flex: 1 1;
}

.menuIcon {
  width: 24px;
  height: 22px;
}

.menuIcon img {
  width: 100%;
  height: 100%;
}

.menuItemActive > .menuIcon img {
  filter: brightness(3);
}

.menuTitle {
  font-size: 14px;
  margin-bottom: 4px;
  line-height: 120%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.menuDescription {
  font-size: 12px;
  margin: 0;
  line-height: 120%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.popup,
.popupMenu {
  display: block;
  background: white;
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #302c32;
}

.popup {
  min-width: 320px;
  width: 100%;
  max-width: 360px;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.popup.expanded {
  max-width: calc(100% - 114px);
}

.popupMenu {
  padding: 20px 0px;
  width: 218px;
  overflow: auto;
  max-height: 100%;
}

.popupHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 24px;
}

.popupHeaderText {
  padding-right: 15px;
  font-weight: bold;
}

.popupAddressContainer {
  background-color: #edf2fe;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
}

.popupAddress {
  font-size: 14px;
  line-height: 20px;
  margin-left: 11px;
}

.popupClose {
  display: flex;
  cursor: pointer;
}

.popupAppeals {
  overflow: auto;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .popup {
    max-width: 100%;
  }
}

@media screen and (max-width: 415px) {
  .popup {
    position: fixed;
  }
}
