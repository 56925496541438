.wrapper {
  width: 100%;
  padding: 64px 0;
}

.container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  max-width: var(--lnd-container-width);
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
}

@media screen and (max-width: 960px) {
  .container {
    max-width: 100%;
    grid-template-columns: repeat(6, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .container {
    max-width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
