.currentRegionWrapper {
  position: relative;
  display: flex;
}
.currentRegionWrapper_opened::before {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--lnd-blue-link);
  opacity: 0.5;
  z-index: 10;
}

.currentRegionWrapper > * {
  color: var(--lnd-blue-link);
}

.currentRegion {
  cursor: pointer;
}

.wrapper {
  position: absolute;
  top: 44px;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 250px;
  width: 460px;
  padding: 20px;
  margin: auto;
  background: white;
  outline: none;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.regionsListWrapper {
  height: 100%;
}

.regionsList {
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  width: 100%;
  padding: 0;
  background-color: white;
  list-style-type: none;
}

@media screen and (max-width: 767px) {
  .wrapper {
    position: fixed;
    left: 5px;
    top: 5px;
    width: 85vw;
    height: 90vh;
  }

  .currentRegionWrapper_opened::before {
    content: '';
  }
}
