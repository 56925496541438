.ymaps-2-1-79-map ymaps,
.ymaps-2-1-79-map ymaps:after,
.ymaps-2-1-79-map ymaps:before {
  box-sizing: border-box !important;
}

.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright {
  display: none !important;
}

.ymaps-2-1-79-float-button {
  padding-left: 0 !important;
  border-radius: 12px !important;
}

.ymaps-2-1-79-zoom__plus {
  border-radius: 12px 12px 0 0 !important;
}

.ymaps-2-1-79-zoom__minus {
  border-radius: 0 0 12px 12px !important;
}

.ymaps-2-1-79-zoom {
  padding: 43px 0 !important;
}

.ymaps-2-1-79-controls__control .ymaps-2-1-79-zoom .ymaps-2-1-79-float-button {
  height: 43px !important;
  width: 40px !important;
}

.ymaps-2-1-79-zoom__icon,
.ymaps-2-1-79-float-button-icon {
  width: 100% !important;
  height: 100% !important;
  border: none !important;
}

.ymaps-2-1-79-zoom__plus .ymaps-2-1-79-zoom__icon {
  background-image: url('../assets/img/landing/plus.svg') !important;
}

.ymaps-2-1-79-zoom__minus .ymaps-2-1-79-zoom__icon {
  background-image: url('../assets/img/landing/minus.svg') !important;
}

.ymaps-2-1-79-float-button-icon_icon_geolocation {
  height: 40px !important;
  background-image: url('../assets/img/landing/geolocation.svg') !important;
}

.ymaps-2-1-79-controls__control .ymaps-2-1-79-float-button {
  width: 40px !important;
  height: 40px !important;
}

.ymaps-2-1-79-default-cluster {
  color: #ffffff !important;
}
