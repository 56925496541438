.polls-wrapper {
  padding: 64px 0;
  width: 100%;
  background: var(--lnd-blue-light);
}

.polls-headline {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin: 32px auto;
  grid-column: 3 / -3;
}

.polls-img {
  grid-column: 1 / 3;
}

.polls-title {
  grid-column: 3 / -1;
}

.polls-container {
  width: 100%;
  max-width: 1008px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  grid-column: 1 / -1;
  margin: 0 auto;
  margin-bottom: 32px;
}

.polls-block {
  width: 100%;
  margin-right: 32px;
}

.polls-block:last-child {
  margin-right: 0;
}

.polls-block:only-child {
  grid-column: 1 / -1;
}

.poll {
  width: 100%;
  height: 100%;
  padding: 24px;
  background: #ffffff;
  box-shadow: 0px 1px 4px #e3ebfc, 0px 24px 48px rgba(230, 235, 245, 0.4);
  border-radius: 12px;
}

.poll-headline {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.poll-subheadline {
  width: 100%;
  display: flex;
  align-items: center;
}

.poll-subheadline img {
  margin-right: 16px;
}

.poll-headline h5 {
  white-space: normal;
}

.poll-description {
  width: 100%;
  margin: 16px 0 24px;
}

.poll-description p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--lnd-grey-blue);
}
.polls {
  display: grid;
}

.polls-list {
  width: 100%;
  padding: 0;
  margin-bottom: 16px;
  list-style: none;
}
.polls-list:only-child {
  width: 100%;
}

.polls-list li {
  margin-bottom: 16px;
}

.show-all {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.hide-polls {
  grid-column: 1 / -1;
  text-align: center;
}

.hide-polls a {
  font-size: 18px;
}

@media screen and (max-width: 1024px) {
  .polls-headline {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 960px) {
  .polls-container {
    grid-template-columns: 1fr;
  }
  .polls-block {
    margin-right: 0;
  }
  .polls {
    flex-direction: column;
  }
}

@media screen and (max-width: 767px) {
  .polls-headline {
    grid-template-columns: 1fr;
  }

  .polls-img {
    text-align: center;
    margin-bottom: 24px;
  }
}
