.accordion {
  width: 100%;
  padding: 24px;
  margin: 24px auto;
  background-color: #fff;
  box-shadow: 0 1px 4px #e3ebfc, 0 24px 48px rgba(230, 235, 245, 0.4);
  border-radius: 12px;
}

.accordion.open > .content {
  display: block;
}

.accordion.open > .headline .iconWrap {
  transform: rotate(0deg);
}

.headline {
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  padding: 0 10px 0 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: #0B1F33;
}

.iconWrap {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: #E4ECFD;
  border-radius: 50%;
  transition: transform ease-in-out 0.25s;
  transform: rotate(180deg);
}

.content {
  margin-top: 24px;
  display: none;
}

.content li,
.content p {
  color: var(--lnd-grey-blue);
}
