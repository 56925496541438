@font-face {
  font-family:"Helvetica Neue";
  src:url('../assets/fonts/helveticaneuecyr-roman-webfont.woff') format("woff"),
  url('../assets/fonts/helveticaneuecyr-roman-webfont.woff') format("woff");
}

/*todo *** внешние линки на шрифты пока нерабочие */
@font-face {
  font-family:"ALS Ekibastuz Light";
  src:url('../assets/fonts/ALSEkibastuz-Light.woff') format("woff");
  /*src: url('https://pos2.test.gosuslugi.ru/bin/fonts/Ekibastuz/ALSEkibastuz-Light.woff') format("woff");*/
}

@font-face {
  font-family:"ALS Ekibastuz Regular";
  src:url('../assets/fonts/ALS Ekibastuz Regular.otf') format("opentype");
  /*src: url('https://pos2.test.gosuslugi.ru/bin/fonts/Ekibastuz/ALS Ekibastuz Regular.otf') format("opentype");*/
}

@font-face {
  font-family:"ALS Ekibastuz Bold";
  src:url('../assets/fonts/ALS Ekibastuz Bold.otf') format("opentype");
  /*src: url('https://pos2.test.gosuslugi.ru/bin/fonts/Ekibastuz/ALS Ekibastuz Bold.otf') format("opentype");*/
}

@font-face {
  font-family:"ALS Ekibastuz Heavy";
  src:url('../assets/fonts/ALS Ekibastuz Heavy.otf') format("opentype");
  /*src: url('https://pos2.test.gosuslugi.ru/bin/fonts/Ekibastuz/ALS Ekibastuz Heavy.otf') format("opentype");*/
}

@font-face {
  font-family:"ALS Ekibastuz Black";
  src:url('../assets/fonts/ALS Ekibastuz Black.otf') format("opentype");
  /*src: url('https://pos2.test.gosuslugi.ru/bin/fonts/Ekibastuz/ALS Ekibastuz Black.otf') format("opentype");*/
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Bold.woff2") format("woff2"), url("../assets/fonts/Lato-Bold.woff") format("woff");

  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-BlackItalic.woff2") format("woff2"), url("../assets/fonts/Lato-BlackItalic.woff") format("woff");

  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Black.woff2") format("woff2"), url("../assets/fonts/Lato-Black.woff") format("woff");

  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-HeavyItalic.woff2") format("woff2"), url("../assets/fonts/Lato-HeavyItalic.woff") format("woff");

  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Heavy.woff2") format("woff2"), url("../assets/fonts/Lato-Heavy.woff") format("woff");

  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Medium.woff2") format("woff2"), url("../assets/fonts/Lato-Medium.woff") format("woff");

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src:
    url("../assets/fonts/Lato-HairlineItalic.woff2") format("woff2"),
    url("../assets/fonts/Lato-HairlineItalic.woff") format("woff");

  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Lato Hairline";
  src: url("../assets/fonts/Lato-Hairline.woff2") format("woff2"), url("../assets/fonts/Lato-Hairline.woff") format("woff");

  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-BoldItalic.woff2") format("woff2"), url("../assets/fonts/Lato-BoldItalic.woff") format("woff");

  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Light.woff2") format("woff2"), url("../assets/fonts/Lato-Light.woff") format("woff");

  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-LightItalic.woff2") format("woff2"), url("../assets/fonts/Lato-LightItalic.woff") format("woff");

  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Italic.woff2") format("woff2"), url("../assets/fonts/Lato-Italic.woff") format("woff");

  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src:
    url("../assets/fonts/Lato-SemiboldItalic.woff2") format("woff2"),
    url("../assets/fonts/Lato-SemiboldItalic.woff") format("woff");

  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Thin.woff2") format("woff2"), url("../assets/fonts/Lato-Thin.woff") format("woff");

  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-MediumItalic.woff2") format("woff2"), url("../assets/fonts/Lato-MediumItalic.woff") format("woff");

  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Semibold.woff2") format("woff2"), url("../assets/fonts/Lato-Semibold.woff") format("woff");

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-Regular.woff2") format("woff2"), url("../assets/fonts/Lato-Regular.woff") format("woff");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: url("../assets/fonts/Lato-ThinItalic.woff2") format("woff2"), url("../assets/fonts/Lato-ThinItalic.woff") format("woff");

  font-weight: 100;
  font-style: italic;
  font-display: swap;
}
