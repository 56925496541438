.layout {
  position: relative;
  height: auto !important;
  min-height: auto !important;
  font-family: Lato, sans-serif;
  display: flex;
  flex-direction: column;
}

.layout h2 {
  font-size: 36px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 24px;
  color: var(--lnd-blue-dark);
}

.layout h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: 24px;
  color: var(--lnd-blue-dark);
}

.layout h5 {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  color: var(--lnd-blue-dark);
}

.layout p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--lnd-blue-dark);
}

.layout a, .layout a:focus, .layout a:visited {
  color: var(--lnd-blue-link);
}

@media screen and (max-width: 1024px) {
  .layout h2 {
    font-size: 32px;
  }

  .layout h3 {
    font-size: 24px;
    line-height: 32px;
  }
}