.carousel-wrapper {
  background: var(--lnd-blue-light);
}

.carousel-headline {
  grid-column: 3 / 11;
  text-align: center;
}

.carousel {
  width: 100%;
  grid-column: 1 / -1;
}

.carousel .slick-list {
  width: 100%;
}

.handset-block-content {
  height: 100%;
  justify-content: space-between;
}

.carousel-img-line {
  bottom: 16px !important;
}

.carousel-img {
  bottom: 18px !important;
  right: 24px !important;
}

@media screen and (max-width: 1280px) {
  .carousel-img {
    right: 12px !important;
  }
}

@media screen and (max-width: 1024px) {
  .carousel {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 960px) {
  .carousel-headline {
    grid-column: 1 / -1;
  }
  .carousel-icon-side {
    display: none!important;
  }
  .landing-carousel .container {
    overflow: hidden;
  }

  .landing-carousel .slick-list {
    overflow: visible;
  }
}

@media screen and (max-width: 767px) {
  .carousel-headline {
    text-align: left;
  }

  .landing-carousel .container {
    overflow: hidden;
  }

  .landing-carousel .slick-list {
    overflow: visible;
  }

  .landing-carousel .slick-slide {
    padding: 0 10px;
    width: 378px;
  }
}
