.wrapper.bottom-modal {
  position: fixed;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 12px 32px rgba(11, 31, 51, 0.18);
  padding: 24px 0;
}

.bottom-modal-container {
  position: relative;
}

.bottom-modal-text {
  grid-column: 1 / -4;
}

.bottom-modal-button {
  grid-column: -4 / -1;
}

.bottom-modal-text,
.bottom-modal-button {
  align-self: center;
}

.bottom-modal-text p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 0;
}

.bottom-modal-button a {
  margin: 0;
}

.close-icon {
  position: absolute;
  right: 5px;
  top: 25%;
  cursor: pointer;
}

@media screen and (max-width: 960px) {
  .bottom-modal-text {
    grid-column: 1 / -3;
  }

  .bottom-modal-button {
    grid-column: -3 / -1;
  }
  .close-icon {
    right: 30px;
  }
}

@media screen and (max-width: 767px) {
  .bottom-modal-text {
    grid-column: 1 / -1;
    margin-bottom: 10px;
    padding: 0 40px;
  }

  .bottom-modal-button {
    grid-column: 1 / -1;
    text-align: center;
  }
  .close-icon {
    top: 0
  }
}