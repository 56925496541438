.applinks-wrapper {
  background-color: var(--lnd-blue-light);
}

.applinks-content {
  grid-column: 5 / -5;
}
.gos-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.app-links {
  margin: 40px 0;
  text-align: center;
}

.app-links__head,
.layout .app-links__head {
  color: var(--lnd-grey-blue);
}

.app-img {
  height: 52px;
  width: 175px;
}

.links-container {
  display: flex;
  justify-content: center;
}

.links-container a {
  display: block;
  margin: 0 10px;
}
.additional-links {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.additional-links a {
  margin-bottom: 16px;
}

@media screen and (max-width: 960px) {
  .applinks-content {
    grid-column: 1 / -1;
  }
}

@media screen and (max-width: 767px) {
  .links-container {
    display: block;
  }
}