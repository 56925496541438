.header {
  width: 100%;
  min-height: 64px;
  background-color: #FFFFFF;
}

.header .header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px 0 5px 0;
}

.logo {
  padding: 5px 20px 5px 0;
}

.logo a {
  display: block;
  line-height: 0;
}

.region {
  padding: 5px 0 5px 0;
}

.nav {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 0 auto;
  padding: 10px 0 10px 20px;
}

.nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}