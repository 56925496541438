.headline {
  grid-column: 3 / 11;
  text-align: center;
}

.mapBlock {
  margin: 32px 0;
  grid-column: 1 / -1;
  overflow: hidden;
}

.mapWrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
  z-index: 0;
}

@media screen and (max-width: 1024px) {
  .headline {
    grid-column: 1 / -1;
  }
}
