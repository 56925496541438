.footer-wrapper {
  background-color: #EFF5F9;
}

.footer-wrapper .footer-container {
  grid-template-columns: repeat(4, 1fr);
}

.footer-container ul {
  list-style: none;
}

.footer-container ul li {
  margin-bottom: 16px;
}

.footer-container ul li a,
.footer-container ul li a:focus,
.footer-container ul li a:visited {
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: var(--lnd-grey-blue);
}

.social-links {
  display: flex;
}

.social-links li {
  margin-right: 16px;
}

@media screen and (max-width: 960px) {
  .footer-wrapper .footer-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 767px) {
  .footer-wrapper .footer-container {
    grid-template-columns: 1fr;
  }
}