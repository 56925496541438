.carousel-container {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

.carousel-icon {
  order: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 6px;
  height: 11px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.carousel-icon-reversed {
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  order: 2;
  width: 6px;
  height: 11px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}

.carousel-icon-side {
  position: absolute;
  padding: 0;
  top: calc(50% - 24px);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #d2e1fb;
}

.carousel-icon-side img {
  width: 7px;
}

.carousel-icon.carousel-icon-side {
  right: -36px;
}

.carousel-icon-reversed.carousel-icon-side {
  left: -36px;
}

.carousel-dots {
  position: static;
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0;
  margin: 0 20px;
  line-height: 9px;
  font-size: 9px;
  order: 3;
  list-style: none;
}

.carousel-dots li button {
  display: block;
  width: 9px;
  height: 9px;
  padding: 0;
  margin: 0;
  border: 1px solid var(--lnd-color-blue);
  color: transparent;
  border-radius: 50%;
  background-color: transparent;
  outline: 0;
  cursor: pointer;
}

.carousel-dots li {
  padding: 0 3px;
}

.carousel-dots li.slick-active button {
  background-color: var(--lnd-color-blue);
}

.carousel-item {
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 40px 76px 1fr;
  width: 275px;
  height: 228px;
  padding: 24px 30px;
  background: #fff;
  box-shadow: 0 6px 10px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: hidden;
  color: #000;
  font-family: var(--lnd-font-decor);
}

.slick-list {
  margin-bottom: 20px;
  padding: 10px 0;
}

.title {
  text-align: center;
}

.slick-slide {
  padding: 0 16px;
}
