.banner-wrapper {
  padding: 0 !important;
  background: linear-gradient(264.18deg, #9EBCFF 0%, #D1E0FF 100%), linear-gradient(270deg, #0D4CD3 0%, #4D83FA 100%), linear-gradient(259.07deg, #417CFF 39.61%, #7EA7FF 100%);
}

.banner-container {
  position: relative;
  display: flex;
  grid-column: 1 / 13;
}

.info {
  padding: 64px 0;
  padding-left: 8%;
  width: 50%;
}

.info h2 {
  margin-bottom: 16px;
  max-width: 460px;
}

.info p {
  margin: 0;
  max-width: 435px;
}

.info .send-message-button {
  margin: 24px 0 0;
}

.image {
  display: flex;
  margin: auto 0 0 0;
  padding-left: 8%;
  width: 50%;
}

.image img {
  max-width: 100%;
  margin: 0 auto;
}

@media screen and (max-width: 960px) {
  .banner-container {
    flex-direction: column;
  }

  .info,
  .image {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }

  .info h2 {
    max-width: none;
  }
  
  .info p {
    max-width: none;
  }
}

@media screen and (max-width: 767px) {
  .info {
    text-align: left;
  }
}