.regionItem {
    cursor: pointer;
    padding: 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0.25px;
    mix-blend-mode: normal;
    width: 50%;
    position: relative;
    text-decoration-line: underline;
    outline: none;
    line-height: 1.2;
}


@media screen and (max-width: 767px) {
    .regionItem {
        padding: 10px 5px 10px 5px;
        width: 100%;
    }
}