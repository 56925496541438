.accordion {
  padding: 12px 24px;
}

.accordion:not(:last-child) {
  border-bottom: 1px solid #e1e1e1;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.accordionTitle {
  opacity: 1;
}

.accordionCloseIcon {
  cursor: pointer;
  width: 24px;
  height: 24px;
  flex: 0 0 24px;
}

.accordionDropdown {
  margin-top: 12px;
}
