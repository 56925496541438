*,
*::before,
*::after {
  box-sizing:border-box;
}

html {
  font-family:var(--lnd-font-main);
  line-height:1.15;
  -webkit-text-size-adjust:100%;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0);
  height:100%;
}


body {
  font-family:var(--lnd-font-main);
}

* {
  box-sizing:border-box;
}

body {
  margin:0;
  font-family:var(--lnd-font-main);
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
  height:100%;
  font-size:1rem;
  font-weight:400;
  line-height:1.5;
  color:var(--lnd-text-color);
  text-align:left;
}

code {
  font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

hr {
  box-sizing:content-box;
  height:0;
  overflow:visible;
}

h1, h2, h3, h4, h5, h6 {
  margin:0;
}

p {
  margin-top:0;
  margin-bottom:1rem;
}

small {
  font-size:80%;
}

a {
  text-decoration:none;
  cursor:pointer;
  /*color: var(--blue);*/
}

a:hover {
  text-decoration:none;
}

a:not([href]):not([tabindex]) {
  text-decoration:none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration:none;
}

a:not([href]):not([tabindex]):focus {
  outline:0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance:button;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance:listbox;
}

textarea {
  overflow:auto;
  resize:vertical;
}

#root {
  display:flex;
  width:100%;
  justify-content:space-between;
  flex-direction:column;
  height:100%;
}

#root > * {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto;
  height: 100%;
}

#root > main {
  min-height:calc(100vh - 75px);
  /*background-color: var(--grey-lighter);*/
}

