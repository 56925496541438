.block {
  position:relative;
  display:flex;
  align-items:center;
  justify-content:center;
  margin:0 auto;
  padding-top:60px;
  padding-bottom:60px;
  border-bottom:1px solid var(--lnd-color-gray);
}

.blockLast,
.block:last-of-type {
  border-bottom: none;
}

.blockTitle,
.blockTitleMb10 {
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 32px;
  line-height: 40px;
  color: #0B1F33;
  text-align: center;
  /*font-family: var(--lnd-font-decor);*/
}

.blockSubTitle {
  margin-bottom: 32px;
  font-size: 16px;
  line-height: 24px;
  max-width: 800px;
  color: #0B1F33;
  text-align: center;
  font-weight: normal;
}

.blockTitleMb10 {
  margin-bottom: 10px;
}

.subTitle {
  margin-bottom: 65px;
  font-weight: bold;
  font-size: 28px;
  color: #000;
  /*font-family: var(--lnd-font-decor);*/
}

@media (max-width:320px) {
  .blockTitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    padding: 0 5px;
  }

  .blockSubTitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    padding: 0 15px;
  }
}

@media (min-width: 321px) and (max-width:767px) {
  .blockTitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .blockSubTitle {
    padding: 0 45px;
  }
}