.progress {
  background-color: #f2f5f7;
  padding: 8px 24px;
  border-radius: 12px;
  width: 258px;
}

.progressBarWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progressBar {
  background-color: #e4ecfd;
  border-radius: 12px;
  overflow: hidden;
  flex: 1 0 auto;
  margin-right: 12px;
}

.progressBarIndicator {
  background-color: #4d83fa;
  height: 5px;
}
