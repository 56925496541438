.send-message-button {
  display: inline-block;
  background: var(--lnd-blue-link);
  border-radius: 8px;
  border: none;
  padding: 14px 24px;
  margin: 24px 0;
  text-align: center;
  color: white !important;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .send-message-button {
    width: 100%;
  }
}