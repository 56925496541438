.materials.wrapper {
    padding: 65px 0 35px 0;
}

.materials .container {
    display: flex;
    flex-direction: column;
}

.layout .materials__head {
    grid-column: 1 / -1;
    margin: 0 auto;
    max-width: 800px;
    text-align: center;
    font-size: 32px;
    color: var(--lnd-blue-dark);
}

.materials__list {
    grid-column: 1 / -1;
    display: grid;
    margin: 0 -15px 0 -15px;
    padding: 25px 0 0 0;
}
.materials__list.materials__list_img-shown {
    grid-template-columns: 60% 40%;
}

.materials__item {
    list-style: none;
    padding: 0 15px 30px 15px;
}
.materials__list.materials__list_img-shown .materials__item:first-child {
    display: grid;
    grid-column: 1;
    grid-row: 1 / 4;
}
.materials__list.materials__list_img-shown .materials__item:nth-child(2) {
    display: grid;
    grid-column: 2;
    grid-row: 1;
}
.materials__list.materials__list_img-shown .materials__item:nth-child(3) {
    display: grid;
    grid-column: 2;
    grid-row: 2;
}
.materials__list.materials__list_img-shown .materials__item:nth-child(4) {
    display: grid;
    grid-column: 2;
    grid-row: 3;
}
.materials__list.materials__list_img-shown .materials__item:nth-child(n+5) {
    display: grid;
    grid-column: 1 / 3;
}

.material {}

.material__content {
    display: flex;
    flex-direction: column;
    padding: 25px;
    height: 100%;
    background-color: var(--lnd-white-main);
    box-shadow: 0px 1px 4px #E3EBFC, 0px 24px 48px rgba(230, 235, 245, 0.4);
    border-radius: 12px;
}

.material__visual {
    padding: 0 0 15px 0;
    min-height: 300px;
    height: 100%;
}

.material__img {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.material__text {}

.layout .material__date {
    margin: 0;
    padding: 0 0 10px 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--lnd-grey-blue);
}

.layout .material__headline {
    display: inline;
    font-weight: 700;
    font-size: 20px;
    line-height: 28px;
    color: var(--lnd-blue-dark);

    /* nice underline */
    background-image: linear-gradient(90deg, var(--lnd-blue-dark), var(--lnd-blue-dark));
    background-size: 0 2px;
    background-position: left bottom;
    background-repeat: no-repeat;
    transition: background-size 0.3s ease-in-out 0.1s;
    /* nice underline end */
}
.layout .material__headline:focus,
.layout .material__headline:visited {
    color: var(--lnd-blue-dark);
}
.layout .material__headline:hover {
    /* nice underline */
    background-size: 100% 2px;
    /* nice underline end */
}


@media screen and (max-width: 960px) {
    .materials__list.materials__list_img-shown .materials__item:nth-child(n) {
        grid-column: 1 / 3;
        grid-row: auto;
    }
}