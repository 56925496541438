/* глобальные стили помечаем подчеркиванием */
._blueAction {
  color:var(--lnd-blue-action);
  cursor:pointer;
}

._blueAction:hover,
._blueAction:focus,
._blueAction:active {
  color:var(--lnd-blue-action-dark);
}