.row {
  display: flex;
  flex-direction: column;
}

.row {
  flex-direction: row;
}

.row + .row {
  margin-top: 12px;
}

.prop {
  color: var(--lnd-grey-blue);
  margin-bottom: 8px;
}

.prop.propBold {
  font-weight: bold;
}

.prop.dark {
  color: #000000;
}

.row .prop {
  margin-bottom: 0;
  padding-right: 22px;
  flex: 0 0 243px;
}

.row .value {
  flex: 1 1 auto;
}
