.map-container {
  grid-column: 1 / -1;
}

.button-container {
  grid-column: 1 / -1;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .appeals-headline {
    grid-column: 1 / -1;
  }
}