.pos-layer-menu {
  min-width: 210px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  background-color: #fff;
  border-radius: 12px;
  padding: 18px 24px;
  box-shadow: 0px 4px 32px 0px #0000002e;
  position: absolute;
  right: 50px;
  top: 50px;
}

.pos-layer-btn {
  font-size: 0;
  background-color: #fff;
  width: 40px;
  height: 40px;
  border: 0;
  cursor: pointer;
  user-select: none;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 12px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.15), 0 2px 5px -3px rgba(0, 0, 0, 0.15);
}

.pos-layer-menu__item {
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-top: 10px;
}

.pos-layer-menu > ymaps:first-child .pos-layer-menu__item {
  margin-top: 0;
}

.pos-layer-checkbox {
  position: relative;
  display: flex;
  align-items: center;
  line-height: 1;
  cursor: pointer;
}

.pos-layer-checkbox__input {
  position: absolute;
  left: -9999px;
  visibility: hidden;
  overflow: hidden;
  width: 0;
  height: 0;
  opacity: 0;
}

.pos-layer-checkbox__input:checked ~ .pos-layer-checkbox__custom::before {
  opacity: 1;
}

.pos-layer-checkbox__custom {
  position: relative;
  flex: 0 0 24px;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  border: 2px solid #0d4cd3;
  border-radius: 50%;
  background-color: transparent;
}

.pos-layer-checkbox__custom::before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  content: '';
  transform: translate(-50%, -50%);
  opacity: 0;
  border: none;
  border-radius: 50%;
  background-color: #0d4cd3;
}

.pos-layer-checkbox__label {
  display: inline-block;
  color: #0b1f33;
  font-size: 14px;
  line-height: 20px;
  user-select: none;
  flex: 1 1 auto;
}
