.item {
  display: block;
  position: relative;
  height: 190px;
  border-radius: 20px;
  filter: drop-shadow(0px 1px 4px #e3ebfc)
    drop-shadow(0px 24px 48px rgba(230, 235, 245, 0.4));
  background: #ffffff;
  overflow: hidden;
}

.item-container {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 24px;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
  background-size: contain;
}

.item-content {
  display: flex;
  max-width: 80%;
  height: 100%;
  flex-direction: column;
}

.item-title {
  position: relative;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: var(--lnd-blue-dark);
  white-space: pre-line;
}

.item-text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--lnd-blue-dark);
  white-space: pre-line;
}

.color-light {
  color: var(--lnd-white-main);
}

@media screen and (max-width: 1280px) {
  .carousel-icon {
    right: -22px !important;
  }

  .carousel-icon-reversed {
    left: -22px !important;
  }

  .item-content {
    max-width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .item {
    height: 190px;
  }
  .item-container {
    padding: 18px;
  }
}

@media screen and (max-width: 960px) {
  .item-title {
    font-size: 16px;
  }

  .item-text {
    font-size: 14px;
    line-height: 20px;
  }
}
