:root {

  /* цвета */

  --lnd-white-main: #ffffff;
  --lnd-blue-dark: #0B1F33;
  --lnd-blue-light: #FAFCFF;
  --lnd-grey-blue: #66727F;
  --lnd-blue-link: #0D4CD3;

  /* контекстные цвета */
  --lnd-text-color:var(--lnd-color-brown);
  --lnd-text-color-dark:var(--lnd-color-dark);

  --lnd-blue-action:hsl(var(--lnd-hsl-color-blue), var(--lnd-lightness-default));
  --lnd-blue-action-dark:hsl(var(--lnd-hsl-color-blue), calc(var(--lnd-lightness-default) - 15%));
  --lnd-blue-action-light:hsl(var(--lnd-hsl-color-blue), calc(var(--lnd-lightness-default) + 15%));

  /* базовые цвета */
  --lnd-color-dark:#3e3e3e;
  --lnd-color-dark-light:#565656;
  --lnd-color-gray:#c8c7cc;
  --lnd-color-gray-light:#e6ebec;
  --lnd-color-brown:#555555;
  --lnd-color-blue-dark:#073983;

  --lnd-color-blue:#0275be;
  --lnd-hsl-color-blue:203, 98%; /* hsl-аналог lnd-color-blue #0275BE, без lightness значения */

  --lnd-lightness-default:38%; /* базовая яркость */

  --inner-color-red:#c22253;
  --inner-color-blue:#0063b0;
  --inner-color-blue-dark:#023e83;
  --inner-color-gray:#535b63;

  /* размеры */
  --lnd-container-width: 1280px;

  /* контекстные размеры */
  --lnd-header-top-line:50px;
  --lnd-header-top-menu:86px;

  /* базовые размеры */
  --lnd-content-width:1180px;
  --lnd-hor-padding:10px;

  /* шрифты */
  --lnd-font-main:"Helvetica Neue", Arial, Helvetica, sans-serif;
  
  --lnd-font-decor:'ALS Ekibastuz Regular', Arial, serif;
  --lnd-font-decor-black:'ALS Ekibastuz Black', Arial, serif;
  --lnd-font-decor-heavy:'ALS Ekibastuz Heavy', Arial, serif;
  --lnd-font-decor-bold:'ALS Ekibastuz Bold', Arial, serif;
  --lnd-font-light:'ALS Ekibastuz Light', Arial, serif;
}