.loaderWrap {
  padding: 12px 24px;
  height: 100%;
  position: relative;
}

.loaderWrap :global(> div) {
  padding-top: 0;
}

.contentPopupTitle {
  font-weight: bold;
}

.contentPopupStatus {
  display: flex;
  align-items: center;
}

.contentPopupStatusIndicator {
  display: block;
  margin-right: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.contentPopupStatusIndicatorInProcess {
  background-color: #f8b200;
}

.contentPopupStatusIndicatorCompleted {
  background-color: #2dc36a;
}

.contentPopupStatusIndicatorClose {
  background-color: #646970;
}

.row + .row {
  margin-top: 12px;
}

.bold {
  font-weight: bold;
}
